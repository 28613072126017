import styled from 'styled-components'
import { Flex } from '@renderbus/common/components'
import { typography, spacing, Media } from '@renderbus/common/theme'

export const PageContainer = styled.div`
  width: 100%;
  background-color: #0b0e0e;
  padding-bottom: 100px;
  ${Media.lessThan(Media.small)} {
    img {
      max-width: 90%;
    }
  }
`
export const BannerContainer = styled.div`
  position: relative;
  padding-top: 60px;
  height: 600px;
  color: white;
  h3 {
    margin: 40px auto;
  }
  ${Media.lessThan(Media.small)} {
    img {
      max-width: 100%;
    }
    h3 {
      padding: 0 ${spacing.base};
    }
  }
`
export const ContentWrapper = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding-top: 100px;
  color: white;
  font-size: ${typography.h4};
  ${Media.lessThan(Media.small)} {
    width: 80%;
  }
`
export const Detail = styled(Flex)`
  justify-content: space-between;
  padding: 0 ${spacing.large};
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    padding: 0;
  }
`
export const Section = styled.section`
  text-align: center;
  img {
    margin-bottom: 50px;
  }
  margin-bottom: 100px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 0;
  }
`
export const Content = styled.div`
  text-align: left;
`

export const Circle = styled(Flex)`
  width: 120px;
  height: 120px;
  border-radius: 50% 50%;
  background: linear-gradient(rgba(19, 203, 117, 0.05), rgba(19, 203, 117, 0.2));
  align-items: center;
  img {
    margin-bottom: 0;
  }
`
export const Date = styled.span`
  ${Media.lessThan(Media.small)} {
    display: block;
  }
`
export const Line = styled.span`
  margin: 0 ${spacing.base};
`
